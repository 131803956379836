import { makeStyles } from '@material-ui/core/styles';

import backgroundGif from '../images/backgroundGif.gif';
import lamp from '../images/lamp.png';
import lampGif from '../images/lamp.gif';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    '@keyframes slidein-right': {
        '0%': {
            transform: 'translateX(7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: theme.spacing(24, 16),
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(10, 14),
        },
        '@media (max-width: 1400px)': {
            paddingBottom: theme.spacing(0),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.common.black,
            padding: theme.spacing(0, 5),
            height: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 5),
        },
    },
    gif: {
        [theme.breakpoints.down('sm')]: {
            background: 'none',
            height: 'auto',
        },
        backgroundImage: `url(${backgroundGif})`,
        backgroundSize: '100vw 110vh',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    container: {
        width: '85%',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    box: {
        border: '1px solid rgba(255,255,255, 0.7)',
        width: '25vh',
        height: '75vh',
        marginBottom: theme.spacing(0),
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundImage: `url(${lamp})`,
        backgroundRepeat: 'no-repeat',
        '&:hover, $description': {
            backgroundImage: `url(${lampGif})`,
        },
    },
    link: {
        color: theme.palette.lightBlue,
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    animationText: {
        animation: '$slidein-left 0.5s ease backwards',
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
        },
    },
    animationGrid: {
        animation: '$slidein-right 0.5s ease backwards',
        height: '75vh',
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
        },
    },
    hidden: {
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
    },
    gridItem: {
        height: '25vh',
    },
    descriptionContainer: {
        padding: theme.spacing(3),
        height: '34%',
        borderTop: '1px solid rgba(255,255,255, 0.7)',
    },
    description: {
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: '0.02em',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px',
            lineHeight: '18px',
        },
        '@media (max-width: 1400px)': {
            fontSize: '11px',
            lineHeight: '13px',
        },
    },
    gifContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    mobileTitle: {
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: '0.01em',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    panelSummaryContent: {
        '&.MuiExpansionPanelSummary-content': {
            margin: theme.spacing(0),
        },
        '&.MuiExpansionPanelSummary-content.Mui-expanded': {
            margin: theme.spacing(0),
        },
    },
    panelSummary: {
        height: '8vh',
        '&.MuiExpansionPanelSummary-root.Mui-expanded': {
            paddingTop: theme.spacing(2),
            margin: theme.spacing(0),
        },
        '&.MuiExpansionPanelSummary-root': {
            minHeight: 32,
            margin: theme.spacing(0),
        },
        '@media (max-width: 330px)': {
            height: '12vh',
        },
    },
    panelDetails: {
        padding: theme.spacing(3),
    },
    panel: {
        backgroundColor: 'transparent',
        border: '1px solid rgba(255,255,255, 0.7)',
        '&.MuiExpansionPanel-root.Mui-expanded': {
            margin: theme.spacing(0),
        },
    },
    hiddenButton: {
        opacity: 0,
        transition: 'opacity 0.5s, transform 1.3s',
        position: 'absolute',
        marginTop: 60,
        zIndex: 1,
    },
    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: '90vh',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    volumeButton: {
        position: 'absolute',
        marginTop: 60,
        zIndex: 9999,
        animation: '$slidein-right 2s ease backwards',
        [theme.breakpoints.down('sm')]: {
            zIndex: 1,
        },
    },
    video: {
        height: 'calc(100vh - 40px)',
        width: '100%',
        backgroundColor: theme.palette.common.black,
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    ytpPauseOverlay: {
        display: 'none !important',
    },
}));
