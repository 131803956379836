import { conjuntives, englishConjuntives } from '../utils/conjuntives';
import {
    systemDescriptionOne,
    systemDescriptionFive,
    systemDescriptionFour,
    systemDescriptionThree,
    systemDescriptionTwo,
    systemDescriptionFourEN,
    systemDescriptionOneEN,
    systemDescriptionThreeEN,
    systemDescriptionTwoEN,
} from '../utils/static';
import { replaceSpacesInString } from 'droptheword';

const descOne = replaceSpacesInString({
    text: systemDescriptionOne,
    dropWordList: conjuntives,
});
const descTwo = replaceSpacesInString({
    text: systemDescriptionTwo,
    dropWordList: conjuntives,
});
const descThree = replaceSpacesInString({
    text: systemDescriptionThree,
    dropWordList: conjuntives,
});
const descFour = replaceSpacesInString({
    text: systemDescriptionFour,
    dropWordList: conjuntives,
});
const descFive = replaceSpacesInString({
    text: systemDescriptionFive,
    dropWordList: conjuntives,
});
const descOneEN = replaceSpacesInString({
    text: systemDescriptionOneEN,
    dropWordList: englishConjuntives,
});
const descTwoEN = replaceSpacesInString({
    text: systemDescriptionTwoEN,
    dropWordList: englishConjuntives,
});
const descThreeEN = replaceSpacesInString({
    text: systemDescriptionThreeEN,
    dropWordList: englishConjuntives,
});
const descFourEN = replaceSpacesInString({
    text: systemDescriptionFourEN,
    dropWordList: englishConjuntives,
});

export const systemDescriptionPL = [
    {
        title: 'BIG \nDATA',
        description: descOne,
        image: 'bigData',
    },
    {
        title: 'ANALIZA \nOBRAZU \nI DŹWIĘKU',
        description: descTwo,
        image: 'analysis',
    },
    {
        title: 'ANALIZA \nPOGODOWA',
        description: descThree,
        image: 'weather',
    },
    {
        title: 'KOMUNIKACJA \nSĄSIEDZKA \nI SAMORZĄDOWA',
        description: descFour,
        image: 'vileo',
    },
    {
        description: descFive,
    },
];
export const systemDescriptionEN = [
    {
        title: 'BIG \nDATA',
        description: descOneEN,
        image: 'bigData',
    },
    {
        title: 'IMAGE \nAND SOUND \n ANALYSIS',
        description: descTwoEN,
        image: 'analysis',
    },
    {
        title: 'WEATHER \nANALYSIS',
        description: descThreeEN,
        image: 'weather',
    },
    {
        title: 'NEIGHBOURLY \n& LOCAL GOVERNMENT \nCOMMUNICATION',
        description: descFourEN,
        image: 'vileo',
    },
    {
        description: descFive,
    },
];
