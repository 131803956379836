import React, { useState, useRef, useEffect, Fragment } from 'react';
import { navigate } from '@reach/router';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { useStyles } from '../styles/system.styles';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import StyledGif from '../components/StyledGif';
import Civileo from '../images/Civileo.svg';

import {
    systemDescriptionPL,
    systemDescriptionEN,
} from '../utils/systemDescription';
import { handleObserver, handleVideoObserver } from '../utils/observer';
import {
    systemAboutTextPartOnePL,
    systemAboutTextPartTwoPL,
    systemAboutTextPartOneEN,
    systemAboutTextPartTwoEN,
    descriptionBoxTextPL,
    descriptionBoxTextEN,
} from '../utils/static';

const System = ({ isEnglish }) => {
    const classes = useStyles();
    const [isMuted, setMuted] = useState(true);

    const refSystem = useRef(null);
    const refVideo = useRef(null);
    const [isVisible, setVisible] = useState(false);
    const [shouldShowButton, setShowButton] = useState(true);
    const [description, setDescription] = useState('');
    const [expanded, setExpanded] = React.useState(false);

    const systemTextPartOne = isEnglish
        ? systemAboutTextPartOneEN
        : systemAboutTextPartOnePL;

    const systemTextPartTwo = isEnglish
        ? systemAboutTextPartTwoEN
        : systemAboutTextPartTwoPL;

    const descriptionBoxText = isEnglish
        ? descriptionBoxTextEN
        : descriptionBoxTextPL;

    const systemDescription = isEnglish
        ? systemDescriptionEN
        : systemDescriptionPL;
    useEffect(() => {
        const currentRef = refSystem.current;
        const video = refVideo.current;

        handleVideoObserver(video, isMuted, setShowButton);
        handleObserver(currentRef, setVisible);
    }, [isMuted]);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Layout
            // footerTitle="Vileo"
            href="#vileo"
            color="black"
            backgroundColor="white"
        >
            <SEO title="Civileo" />
            <Box className={classes.gif} id="civileo">
                <div ref={refSystem}>
                    <Box className={classes.root}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid
                                item
                                xs
                                xl
                                lg
                                className={
                                    isVisible
                                        ? classes.animationText
                                        : classes.hidden
                                }
                            >
                                <Hidden only={['sm', 'xs']}>
                                    <img src={Civileo} alt="Logo Civileo" />
                                </Hidden>
                                <Box className={classes.container}>
                                    <Typography variant="body1" gutterBottom>
                                        {systemTextPartOne}
                                        <MuiLink
                                            onClick={() => navigate('#vileo')}
                                            className={classes.link}
                                            underline="none"
                                        >
                                            Vileo
                                        </MuiLink>
                                        {systemTextPartTwo}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Hidden only={['sm', 'xs']}>
                                <Grid
                                    item
                                    container
                                    xs
                                    lg={7}
                                    xl={6}
                                    justify="center"
                                    className={
                                        isVisible
                                            ? classes.animationGrid
                                            : classes.hidden
                                    }
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        className={classes.gridContainer}
                                    >
                                        <Box
                                            className={classes.box}
                                            onMouseOver={() =>
                                                setDescription(
                                                    descriptionBoxText,
                                                )
                                            }
                                            onFocus={() =>
                                                setDescription(
                                                    descriptionBoxText,
                                                )
                                            }
                                            onMouseOut={() =>
                                                setDescription('')
                                            }
                                            onBlur={() => setDescription('')}
                                        >
                                            <Box className={classes.box} />
                                        </Box>
                                    </Grid>
                                    <Grid item container xs={8}>
                                        <Box className={classes.gifContainer}>
                                            {systemDescription.map(
                                                (item, index) => (
                                                    <Fragment
                                                        key={
                                                            item.title || index
                                                        }
                                                    >
                                                        {item.title ? (
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                onMouseOver={() =>
                                                                    setDescription(
                                                                        item.description,
                                                                    )
                                                                }
                                                                onFocus={() =>
                                                                    setDescription(
                                                                        item.description,
                                                                    )
                                                                }
                                                                onMouseOut={() =>
                                                                    setDescription(
                                                                        '',
                                                                    )
                                                                }
                                                                onBlur={() =>
                                                                    setDescription(
                                                                        '',
                                                                    )
                                                                }
                                                                className={
                                                                    classes.gridItem
                                                                }
                                                            >
                                                                <StyledGif
                                                                    image={
                                                                        item.image
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </StyledGif>
                                                            </Grid>
                                                        ) : (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className={
                                                                    classes.descriptionContainer
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.description
                                                                    }
                                                                >
                                                                    {
                                                                        description
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Fragment>
                                                ),
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden only={['xl', 'lg', 'md']}>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {descriptionBoxText}
                                    </Typography>
                                    <Box mb={5} />

                                    {systemDescription.map((item, index) => (
                                        <Fragment key={item.title || index}>
                                            {item.title && (
                                                <ExpansionPanel
                                                    square
                                                    expanded={
                                                        expanded ===
                                                        `panel${index + 1}`
                                                    }
                                                    onChange={handleChange(
                                                        `panel${index + 1}`,
                                                    )}
                                                    className={classes.panel}
                                                >
                                                    <ExpansionPanelSummary
                                                        classes={{
                                                            root:
                                                                classes.panelSummary,
                                                            content:
                                                                classes.panelSummaryContent,
                                                        }}
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.mobileTitle
                                                            }
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails
                                                        className={
                                                            classes.panelDetails
                                                        }
                                                    >
                                                        <Typography variant="body2">
                                                            {item.description}
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )}
                                        </Fragment>
                                    ))}
                                    <Box mb={5} />
                                </Box>
                            </Hidden>
                        </Grid>
                    </Box>
                </div>
            </Box>
            <div ref={refVideo} className={classes.videoContainer}>
                <iframe
                    src="https://www.youtube.com/embed/6WH7zRl0ovU"
                    width="100%"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"
                    autoplay="autoplay"
                ></iframe>
            </div>
        </Layout>
    );
};
export default System;
