import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import analysis from '../images/analysis.gif';
import bigData from '../images/bigData.gif';
import vileo from '../images/vileo.gif';
import weather from '../images/weather.gif';

const styledBy = (property, mapping) => props => mapping[props[property]];

const styles = {
    root: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '100%',
        padding: '48px 24px',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '20px',
        whiteSpace: 'pre-line',
        letterSpacing: '0.01em',
        borderTop: '1px solid rgba(255,255,255, 0.7)',
        borderRight: '1px solid rgba(255,255,255, 0.7)',
        '@media (max-width: 1400px)': {
            fontSize: '14px',
            lineHeight: '18px',
            padding: '24px 16px',
        },
        '&:hover': {
            backgroundImage: styledBy('image', {
                analysis: `url(${analysis})`,
                bigData: `url(${bigData})`,
                vileo: `url(${vileo})`,
                weather: `url(${weather})`,
            }),
            color: 'transparent',
        },
    },
};

const StyledGif = withStyles(styles)(
    ({ classes, image, children, ...other }) => (
        <Box className={classes.root} {...other}>
            {children}
        </Box>
    ),
);
export default StyledGif;
